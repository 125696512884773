<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>快递模板管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="addTpl()">新增模板</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%" default-expand-all>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand" style="padding-left: 40px;padding-right: 100px;">
              <div class="address-row" v-for="address in props.row.noorderAddressList">
                <span>{{address.prov}}{{address.city}}{{address.area}}</span> 
                <span v-if="address.isNcp" >限发地区</span>
                <span v-else-if="address.isAnotherPostage" >附加邮费({{address.postageSurcharge}})</span>
                <div>
                  <a class="show-a" @click="addAddress(props.row, address)">编辑</a>
                  <a class="show-a" @click="deleteAddress(address.id)">删除</a>
                </div>
              </div>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="模板名称" prop="templateName" />
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="操作">
          <template slot-scope="props">
            <el-button type="success" @click="addAddress(props.row, null)">编辑规则</el-button>
            <el-button type="primary" @click="addTpl(props.row)">编辑名称</el-button>
            <el-button type="danger" @click="deleteData(props.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="提示" :visible.sync="tplDialogVisible" width="50%">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-form-item label="名称" >
          <el-input v-model="templateName" placeholder="" />
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTemplate">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
      <!-- 主要内容 -->
      <div v-if="isAnotherPostage == -1">
        <el-form label-width="100px">
          <el-form-item label="疫情限发" >
            <div><el-radio v-model="selectType" label="0">限发地区</el-radio></div>
            <div><el-radio v-model="selectType" label="1">附加邮费</el-radio></div>
          </el-form-item>
        </el-form>
        <!-- 底部 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="next">下一步</el-button>
        </span>
      </div>
      <div v-else>
        <el-form label-width="100px">
          <el-form-item label="省" required>
            <el-select v-model="provIndex" >
                <el-option v-for="(item,idx) in address" :key="idx" :label="item.province" :value="idx" />
            </el-select>
          </el-form-item>
          <el-form-item label="市" >
            <el-select v-model="city" v-if="address[provIndex]">
                <el-option v-for="(item,idx) in address[provIndex].city" :key="idx" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="区" >
            <el-input v-model="area" placeholder="区" />
          </el-form-item>
          <el-form-item label="疫情限发" v-if="isAnotherPostage == 0">
            <div><el-radio v-model="isNcp" label="1">是</el-radio></div>
            <div><el-radio v-model="isNcp" label="0">否</el-radio></div>
          </el-form-item>
          <el-form-item label="附加邮费" required v-else>
            <el-input v-model="postageSurcharge" placeholder="请输入附加邮费金额" />
          </el-form-item>
        </el-form>
        <!-- 底部 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save">保 存</el-button>
        </span>
      </div>
      
    </el-dialog>
  </div>
</template>
//
<script>
export default {
  data () {
    return {
      templateName: '',
      list: [],
      address: [{city: []}],
      citys: [],
      provIndex: 0,
      city: '--',
      dialogVisible: false,
      tplDialogVisible: false,
      area: '',
      currentId: '',
      isNcp: '0',
      addressId: '',
      postageSurcharge: '',
      isAnotherPostage: -1,
      selectType: '1'
    }
  },
  created () {
    // 订单列表
    this.GetList();
    this.GetAddressList();
  },
  methods: {
    GetAddressList () {
      this.$api.GetAddressList().then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        
        this.address = res.data.map(o => {
          o.city = eval(o.city);
          return o;
        })
      })
    },
    GetList () {
      this.$api.GetTemplateList({currentPage: 1, pageSize: 100}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        res.data.records.map(o => {
          o.noorderAddressList.sort((a,b) => {
            if(a.prov && a.prov != b.prov){
              return a.prov.localeCompare(b.prov,"zh")
            }else if(a.city && a.city != b.city){
              return a.city.localeCompare(b.city,"zh")
            }else if(a.area){
              return a.area.localeCompare(b.area,"zh")
            }
            return 1;
          })
        })
        this.list = res.data.records
      })
    },
    deleteData(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteTemplate({id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    addTpl (data) {
      this.tplDialogVisible = true
      if(data){
        this.currentId = data.id;
        this.templateName = data.templateName;
      }else{
        this.currentId = null;
        this.templateName = '';
      }
    },

    saveTemplate () {
      if(!this.templateName) return this.$message.error('请输入快递模板名称')

      const params = {templateName: this.templateName};
      let promise = null;
      if(this.currentId){
        params.id = this.currentId;
        promise = this.$api.UpdateTemplate(params)
      }else{
        promise = this.$api.AddTemplate(params)
      }
      promise.then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败:' + res.message)
        this.$message.success('保存成功')
        this.tplDialogVisible = false;
        this.GetList();
      })
    },

    addAddress(data, address){
      this.currentId = data.id;
      this.addressId = '';
      this.provIndex = 0 
      this.city = ''
      this.area = ''
      this.isNcp = '1'
      this.isAnotherPostage = -1
      this.selectType = '0'
      this.postageSurcharge = ''
      if(address){
        this.addressId = address.id
        this.provIndex = this.address.findIndex(o => o.province === address.prov) 
        this.city = address.city
        this.area = address.area
        this.isNcp = address.isNcp.toString()
        this.postageSurcharge = address.postageSurcharge
        this.isAnotherPostage =address.isAnotherPostage == 1 ? '1' : '0'
      }
      this.dialogVisible = true;
    },
    save () {

      let prov = this.address[this.provIndex].province;
      let city = this.city == '--' ? null : this.city;
      const params = {
        templateId: this.currentId, 
        prov, 
        city, 
        area: this.area, 
        isAnotherPostage: this.isAnotherPostage,
      }
      if(this.isAnotherPostage){
        params.postageSurcharge = this.postageSurcharge;
      }else{
        params.isNcp = this.isNcp
      }
      if(this.addressId){
        params.id = this.addressId;
        this.$api.UpdateNoOrderAddress(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('请求失败')
          }
          this.$message.success('保存成功')
          this.dialogVisible = false;
          this.GetList();
        })
      }else{

        this.$api.AddNoOrderAddress(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('请求失败')
          }
          this.$message.success('保存成功')
          this.dialogVisible = false;
          this.GetList();
        })
      }
    },
    deleteAddress(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteNoOrderAddress({id: id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    next(){
      this.isAnotherPostage = this.selectType
    }
  },
}
</script>
<style lang="scss" scoped>
.show-a{
    color: #439fe0;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
  }
.address-row{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 20px;
  &:nth-child(2n){
    background: #f9f9f9;
  }
  > span {
    &:nth-child(1){
      flex: 0 0 40%;
    }
  }
}
</style>
